.page-container {
    display: flex;
    flex-direction: column;
    width: calc(100%-350px);
}

.header {
    height: 75px;
    display: flex;
    flex-direction: row;
    padding:10px;
    padding-left: 20px !important;
    border-bottom: 3px solid black;
    align-items: center;
}

.header-text {
    font-weight: bolder;
    color: black;
    font-size: 35px;
}

.add-user-icon {
    margin-right: 20px;
    margin-left: auto;
    cursor: pointer;
}

.body {
    height: calc(100% - 75px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: scroll;
}

.emp-table-container {
    flex: 1;
    border: 3px solid black;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

.btn-edit {
    color: goldenrod;
}

.btn-edit-container :hover {
    cursor: pointer;
}

.btn-delete {
    color: red;
}

.btn-delete-container :hover {
    cursor: pointer;
}

.MuiPaper-root {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.MuiTableContainer-root {
    flex: 1;
}