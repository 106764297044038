/* Main Container */
.side-bar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 350px;
    border-right: 3px solid black;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.3);
    position: fixed;
}

/* Header Styles */
.head {
    height: 75px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:10px;
    border-bottom: 3px solid black;
}

.green-text {
    color: #57961C;
    font-weight: bolder;
}

.eas-logo {
    height:60px;
    width: 60px;
    padding: 5px;
    background-color: white;
    border-radius: 40px;
    border: 3px solid black;
}

.head-header {
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
}

/* Body Styles */
.body {
    flex:6;
    overflow-y: scroll;
}

.link-container {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 25px;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.active {
    border-left: 5px solid #57961C;
    border-bottom: 5px solid #57961C;
    border-top: 1px solid #57961C;
    border-right: 1px solid #57961C;
    box-shadow: -5px 5px 5px rgba(87, 150, 28, 0.3);
}

.link-text {
    padding: 10px;
    padding-left: 20px;
    font-size: 25px;
    font-weight: bolder;
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

/* Footer Styles */
.footer {
    flex: 1;
    border-top: 3px solid black;
    flex-direction: row;
    display: flex;
    padding:20px;
    justify-content: center;
    align-items: center;
}

.emp-name {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.emp-options {
    flex: 1;
    cursor: pointer;
}

.name {
    font-weight: bolder;
    font-size: 20px;
}

.title {
    font-size: 16px;
}

.pole-icon {
    height: 30px;
    width: 30px;
}