.emppick-modal-container {
    display: flex;
    flex-direction: column;
}

.emppick-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.emppick-header-text {
    color: black;
    font-weight: bold;
    font-size: 24px;
}

.modal-close-icon:hover {
    cursor: pointer;
}

.text-field-input {
    margin: 20px;
}