.page-container {
    display: flex;
    flex-direction: column;
}

.header {
    height: 75px;
    display: flex;
    flex-direction: row;
    padding:10px;
    padding-left: 20px !important;
    border-bottom: 3px solid black;
    align-items: center;
}

.header-text {
    font-weight: bolder;
    color: black;
    font-size: 35px;
}

.body {
    flex: 7;
}

.form-selection-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 3px solid black;
}