.page-container {
    display: flex;
    flex-direction: column;
}

.header {
    height: 75px;
    display: flex;
    flex-direction: row;
    padding:10px;
    padding-left: 20px !important;
    border-bottom: 3px solid black;
    align-items: center;
}

.header-text {
    font-weight: bolder;
    color: black;
    font-size: 35px;
}

.body {
    flex: 7;
}

.cred-table-container {
    flex: 1;
    border: 3px solid black;
    border-radius: 3px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
}

.btn-reset-container :hover {
    cursor: pointer;
}

.btn-reset {
    color: goldenrod;
}

.btn-update {
    color: #57961C;
}