.map-container {
    padding: 0 !important;
    margin: 0 !important;
    flex: 1;
    height: 100vh;
    background-color: black;
    flex-direction: column;
    display: flex;
}

.map {
    width: 100%;
    height: 100vh;
    flex: 1;
}

.control-bar {
    height: 80px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid black;
}

.left-control-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-text {
    color: black;
    font-size: 24px;
    font-weight: bold;
}

.go-back-btn {
    background-color: white;
    margin: 10px;
    cursor: pointer;
}

.filter-options {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.apply-filter-btn {
    background-color: green;
    cursor: pointer;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 10px;
}

.apply-filter-btn-text {
    color: white;
}

.pole-map-info-window-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pole-map-info-window-pole-number-text {
    font-size: 24px;
    color: #57961C;
    font-weight: bold;
    padding: 3px;
}

.pole-map-info-window-town-state-text {
    font-size: 16px;
    color: black;
    padding: 3px;
}