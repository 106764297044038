.pt-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.pt-row {
    flex: 1;
}

.add-tester-btn {
    margin-top: 10px;
    padding: 10px;
    background-color: #57961C;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 3px solid black;
}

.add-tester-btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

.add-tester-btn-text {
    color: black;
    font-weight: bold;
    font-size: 20px;
}