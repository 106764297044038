.p-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.p-control-row {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.p-control-apply-btn {
    background-color: #57961C;
    border: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
}

.p-control-apply-btn:hover {
    opacity: 0.8;
    cursor: pointer;
}

.p-control-apply-btn-text {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.text-field {
    margin-right: 10px;
}

.p-packet-control-row {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.packet-add-btn {
    padding-left: 20px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.p-packet-pole-packet-table {
    margin-top: 20px;
}

.p-packet-btn-delete {
    color: red;
    cursor: pointer;
}

.p-btn-direction {
    color: blue;
    cursor: pointer;
}

.p-packet-btn-edit {
    color: green;
    cursor: pointer;
}