.packet-pole-select-modal-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.select-pole-modal-control-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 3px solid black;
}

.select-pole-modal-control-section {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.select-pole-modal-control-section-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.select-pole-modal-control-section-stack {
    display: flex;
    flex-direction: column;
}

.select-pole-modal-pole-count-text {
    color: black;
    font-size: 20px;
    font-weight: bold;
}

.select-pole-modal-pole-current-text {
    color: black;
    font-size: 14px;
}

.select-pole-modal-save-btn {
    padding: 10px;
}

.select-pole-modal-close-btn {
    padding: 10px;
}

.modal-save-btn {
    color: green;
    cursor: pointer;
}

.modal-close-btn {
    cursor: pointer;
}

.pole-packet-select-map-container {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
}

.pole-packet-select-map {
    flex: 1;
}