.modal-container {
    display: flex;
    flex-direction: column;
}

.modal-row {
    display: flex;
    flex-direction: row;
}

.modal-close-icon {
    margin-left: auto;
}

.text-field {
    flex: 1;
}

.text-field-input {
    margin: 20px;
}

.modal-submit-button {
    width: auto;
    margin: 20px;
}