.expense-report-control-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

    .expense-report-control-controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .expense-report-control-apply {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .expense-report-control-apply-btn {
        background-color: #57961C;
        padding: 10px;
        border: 3px solid black;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
    }

    .expense-report-control-apply-btn:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    .expense-report-control-apply-btn-text {
        color: black;
        font-size: 20px;
        font-weight: bold;
    }

    .expense-report-download-btn {
        margin-right: 20px;
        cursor: pointer;
    }



.expense-report-table {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

