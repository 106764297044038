.body {
    padding-left: 0;
    padding-right: 0;
}

.tab-container {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: flex-start;
    border-bottom: 3px solid black;
}

.tab {
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
}

.tab:hover {
    background-color: #57961C;
    opacity: 0.8;
    cursor: pointer;
}

.tab-active {
    background-color: #57961C;
    border-top: 3px solid black;
    border-right: 3px solid black;
    border-left: 3px solid black;
}

.tab-text {
    color: black;
    font-size: 20px;
    font-weight: bold;
}