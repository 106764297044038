.pr-container {
    display: flex;
    flex-direction: column;
}

.pr-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
}

.pdf-button {
    background-color: #dd0000;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 3px solid black;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.excel-button {
    background-color: #57961C;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 3px solid black;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pdf-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.excel-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.btn-text {
    flex: 1;
    color: black;
    font-weight: bold;
    font-size: 16px;
}

.pr-table-container {
    padding: 10px;
}