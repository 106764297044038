.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/eas.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; 
}

.login-card-container {
    width: '25%';
}

.login-card {
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-field {
    margin-bottom:20px;
}