.App {
  text-align: center;
  display: flex;
  flex-direction: row;
}

.page-container {
  display: flex;
  flex-direction: column;
  width: calc(100%-350px);
  margin-left: 350px;
}

/* Utilities */
@media (max-width: 1200px) {
  .hidden-mobile {
    display: none !important;
    padding: 0px !important;
  }
  .side-bar-container {
    width: 85px !important;
  }
  .page-container {
    width: calc(100%-85px) !important;
    margin-left: 85px !important;
  }
  .link-container {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    padding: 5px !important;
    justify-content: center !important;
  }
  .pr-row {
    flex-direction: column !important; 
  }
}